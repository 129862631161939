import React, { Component } from 'react';
import { TopSimpleHeader, TouchControl } from '../../organisms';
import { getHomeProjectsLoop } from './../../../API'
import { setDisplayKioskModeScreenSendMessage } from '../../../WS/'
import './style.css';


class WaitingPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            load        : false,
        };
        this.getData = this.getData.bind( this );
    }
    
    getData() {
        getHomeProjectsLoop().then( ( response ) => {
            this.setState({ 
                load        : true,
            });
            this.props.onBlurSearch();
        }).catch( ( error ) => { 
            this.props.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });
    }
    
    componentDidMount() {        
        this.getData();
        setTimeout( () => {
            this.getKioskModeScreens();         
        }, 2000 );        

    }

    getKioskModeScreens() {
        if ( this.props.WSReady ) {
            setDisplayKioskModeScreenSendMessage( this );        
        }
    }

    componentDidUpdate( prevProps, prevState ) {
        if ( prevProps.WSReady === false && this.props.WSReady ) {
            this.getKioskModeScreens();            
        }
    }

    render() {
        
        var loadClass = ( this.state.load ) ? 'loaded' : 'fetching'; 

        return(
            <div className={ 'page waiting-page ' + loadClass }>
                <div className={ 'vertical-flex' }>
                    <div className={ 'vertical-flex-fix header-holder ts ts-ease-out' }>
                        <TopSimpleHeader />
                    </div>
                    <div className={ 'vertical-flex-flexible touch-control-holder ts ts-ease-out' }>
                        <TouchControl />
                    </div>
                </div>
            </div>
        )
    }
}

export default WaitingPage;