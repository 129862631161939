import React, { Component } from 'react';
import { setup } from '../../../config/';
import './style.css';

class SlideScreen extends Component {

    renderOnCLickBox( props ) {
        var originWP = 'service.gcadscreens.com';
        var originCDN = 'daohhawy174np.cloudfront.net';
        var firstClass = ( props.index === 0 ) ? ' first' : '';
        const addIcon = ( props.mode === 'video' ) ? <span className={'supersymetric icon-play'}></span> : '';          
        var imgTitle = ( props.title ) ? props.title : props.index;
        var imgSrc = ( props.thumb ) ? <img src={ props.thumb.replace( originWP, originCDN ) } className={ 'ts ts-slide-out' } alt={ imgTitle } /> : '';
        var isCurrent = ( props.index === props.display ) ? ' displaying' : '';
        return(
            <div className={ 'atom slide-screen ts ts-quick '  + props.addclass + isCurrent + firstClass  + props.addclassFS } key={ props.index } onClick={ () => { props.onClick( props.index ) } }>
                <figure>
                    { imgSrc }
                    { addIcon }
                </figure>
                <div className={ 'copy-holder' }>
                    <h3 className={ 'ts ts-quick' }>{ imgTitle }</h3>
                </div>
            </div>
        )
    }

    renderLinkBox( props ) {
        var firstClass = ( props.index === 0 ) ? ' first' : '';
        var imgTitle = ( props.title ) ? props.title : '';
        var imgSrc = ( props.thumb.replace( setup.originWP, setup.originCDN ) ) ? <img src={ props.thumb } className={ 'ts ts-slide-out' } alt={ imgTitle } /> : '';
        var isCurrent = ( props.index === props.display ) ? ' displaying' : '';
        return(
            <a href={ props.linkTo } className={ 'atom slide-screen ts ts-quick ' + props.addclass + isCurrent + firstClass + props.addclassFS } key={ props.index }>
                <figure>
                    { imgSrc }
                </figure>
                <div className={ 'copy-holder' }>
                    <h3 className={ 'ts ts-quick' }>{ imgTitle } </h3>
                </div>
            </a>
        )
    }    

    render() {
        var { linkTo } = this.props;
        var renderContent = ( linkTo ) ? this.renderLinkBox( this.props ) : this.renderOnCLickBox( this.props )
        return(
            <div>
                { renderContent }
            </div>
        )
    }
}

export default SlideScreen;