import { setup } from '../config/'

export function getRemoteSocket( that, token ) {

    var webSocket = new WebSocket( setup.remoteSocket + '?token=' + token );

    webSocket.onopen = () => {
        console.log( '.:: RMT webSocket ::: onOpen ::.' );
        that.setState( { WSReady : true });
    }

    webSocket.onclose = () => {
        console.log( '.:: RMT webSocket ::: onClose ::.' );
        that.setState( { WSReady : false });
    }

    webSocket.onerror = ( err ) => {
        that.setState({ WSError : true });
        console.log( '.:: RMT webSocket webSocket ::: onError ::.' );
        webSocket.close();
    };

    return webSocket;
}


export function setDisplayPreviewSendMessage( that, slide ) {
    const { webSocket } = that.props
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'dspVideoWall', [ parseInt( that.props.match.params.wpid ), parseInt( slide ) ] ]
    }
    webSocket.send( JSON.stringify( data ) )
}

export function setDisplayKioskModeScreenSendMessage( that ) {
    const { webSocket } = that.props
    
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'dspVideoWall', [ 'kiosk', 0 ] ]
    }
    webSocket.send( JSON.stringify( data ) )
}

export function setTriggerStatusSendMessage( that ) {
    const { webSocket } = that.props
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'triggerContent', [ that.state.triggerStatus ] ]
    }
    webSocket.send( JSON.stringify( data ) )
}

export function setVideoPlayBack( that, mode ) {
    const { webSocket } = that.props
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'videoPlayBack', [ mode ] ]
    }
    webSocket.send( JSON.stringify( data ) )  
}

export function getMashUpColsSendMessage( that, slide ) {    
    const { webSocket } = that.props
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'dspVideoWall', [ 'mashup', slide ] ]
    }
    webSocket.send( JSON.stringify( data ) );
    that.updateMashupScreens();  
}

export function mashUpColsControlSendMessage( that, p1, p2, p3, p4, f1, f2, f3, f4  ) {
    const { webSocket } = that.props;
    var data = {
        'jsonrpc'   : '2.0',
        'id'        : Date.now(),
        'method'    : 'control_showContent',
        'params'    : [ 'controlMashup', [ p1, p2, p3, p4, f1, f2, f3, f4 ] ]
    }
    webSocket.send( JSON.stringify( data ) )  
    
}


export function getDisplaySocket( that, token ) {

    var webSocket = new WebSocket( setup.displaySocket + '?token=' + token );

    webSocket.onopen = () => {
        console.log( '.:: DSP webSocket ::: onOpen ::.' );
    }

    webSocket.onmessage = ( evt ) => {
        const message = JSON.parse( evt.data );
        console.log( '.:: DSP webSocket ::: onMessage ::.', evt.data );
        if ( message.method === 'screenFeedBack' ) {
            that.setState( { isScreenReady : message.params[0] } );
        } else if ( message.method === 'videoIsReady' ) {
            that.setState( { videoIsReady : message.params[0] } );
        } else if ( message.method === 'videoIsEnd' ) {
            that.setState( { videoIsEnd : message.params[0] } );
        } else if ( message.method === 'videoIsBuffering' ) {
            that.setState( { videoIsBuffering : message.params[0] } );
        }
    }

    webSocket.onclose = () => {
        console.log( '.:: DSP webSocket ::: onClose ::.' );
    }

    webSocket.onerror = ( err ) => {
        that.setState({ WSError : true });
        console.log( '.:: DSP webSocket webSocket ::: onError ::.' );
        webSocket.close();
    };
 
    return webSocket;

}