import React, { Component } from 'react';
import { TopSearchHeader, SearchResultsHolder, MashupSlideHolder } from '../../organisms';
import { getMashUpCols } from './../../../API'
import { getMashUpColsSendMessage, mashUpColsControlSendMessage } from '../../../WS/'

import './style.css';

class MashupPage extends Component {
    
    constructor(props) {
        super(props);
        var inipos = ( this.props.match.params.mpos ) ? parseInt( this.props.match.params.mpos ) : 0;
        var initfeat = ( this.props.match.params.mpos ) ? parseInt( this.props.match.params.mpos ) : false;
        this.state = { 
            load            : false,
            slide1Pos       : inipos,
            slide2Pos       : inipos,
            slide3Pos       : inipos,
            slide4Pos       : inipos,
            slide1Feat      : initfeat,
            slide2Feat      : false,
            slide3Feat      : false,
            slide4Feat      : false,
            slideCols       : false,
            idleTime        : 0
        };

        this.getData            = this.getData.bind( this );
        this.onSlideChange      = this.onSlideChange.bind( this );
        this.onSlideClick       = this.onSlideClick.bind( this );
        this.onDotsClick        = this.onDotsClick.bind( this );

    }

    setIdleTimerCounter() {
        setInterval( () => {
            this.setState( { idleTime : this.state.idleTime + 5 }, () => { this.autoComeBack( this.props.maxIdleTime ) } )
        } , 5000 );
    }

    autoComeBack( max ) {
        if ( this.state.idleTime >= max ) {
            this.props.history.push('/');
        }
    }

    updateMashupScreens() {
        this.setState({ idleTime : 0 });
        var { slide1Pos, slide2Pos, slide3Pos, slide4Pos, slide1Feat, slide2Feat, slide3Feat, slide4Feat } = this.state;
        mashUpColsControlSendMessage( this, slide1Pos, slide2Pos, slide3Pos, slide4Pos, slide1Feat, slide2Feat, slide3Feat, slide4Feat );
    }

    getMashupScreens( props ) {
        if ( this.props.WSReady ) {
            getMashUpColsSendMessage( this, 0 );        
        }
    }
    
    getData() {
        getMashUpCols().then( ( response ) => {
            this.setState({ 
                load        : true,
                slideCols   : response.data,
            }, () => this.getMashupScreens( this.props ) );
        }).catch( ( error ) => { 
            this.props.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });
    }
    
    onSlideChange( item, slideCol ) {
        switch ( slideCol ) {
            case 0  : this.setState( { slide1Pos : item, slide1Feat : false }, () => this.updateMashupScreens() ); break;
            case 1  : this.setState( { slide2Pos : item, slide2Feat : false }, () => this.updateMashupScreens() ); break;
            case 2  : this.setState( { slide3Pos : item, slide3Feat : false }, () => this.updateMashupScreens() ); break;
            default : this.setState( { slide4Pos : item, slide4Feat : false }, () => this.updateMashupScreens() ); break;
        }
    }
    
    onSlideClickSwitchValue( instate, value ) {
        return ( instate === false ) ? value : false;
    };

    onSlideClick( item, slideCol ) {
        var { slide1Feat, slide2Feat, slide3Feat, slide4Feat } = this.state;
        switch ( slideCol ) {
            case 0  : this.setState( { slide1Feat : this.onSlideClickSwitchValue( slide1Feat, item ), slide2Feat : false, slide3Feat : false, slide4Feat : false }, () => this.updateMashupScreens() ); break;
            case 1  : this.setState( { slide1Feat : false, slide2Feat : this.onSlideClickSwitchValue( slide2Feat, item ), slide3Feat : false, slide4Feat : false }, () => this.updateMashupScreens() ); break;
            case 2  : this.setState( { slide1Feat : false, slide2Feat : false, slide3Feat : this.onSlideClickSwitchValue( slide3Feat, item ), slide4Feat : false }, () => this.updateMashupScreens() ); break;
            default : this.setState( { slide1Feat : false, slide2Feat : false, slide3Feat : false, slide4Feat : this.onSlideClickSwitchValue( slide4Feat, item ) }, () => this.updateMashupScreens() ); break;
        }
    }
    
    onDotsClick( item  ) {
        this.setState({
            slide1Pos   : item,
            slide2Pos   : item,
            slide3Pos   : item,
            slide4Pos   : item,
            slide1Feat  : false,
            slide2Feat  : false,
            slide3Feat  : false,
            slide4Feat  : false
        }, () => { this.updateMashupScreens() } );        
    }

    componentDidMount() {        
        this.getData();
        this.setIdleTimerCounter();
    }

    componentWillUnmount() {
        clearInterval( this.state.idleTime );
    }
    
    componentDidUpdate( prevProps, prevState ) {
        if ( prevProps.WSReady === false && this.props.WSReady ) {
            this.getMashupScreens();            
        }
    }
    
    render() {
        
        var { load, slide1Pos, slide2Pos, slide3Pos, slide4Pos, slide1Feat, slide2Feat, slide3Feat, slide4Feat, slideCols } = this.state;
        var loadClass = ( load ) ? 'loaded' : 'fetching'; 

        return(
            <div className={ 'page mashup-page ' + loadClass }>
                <div className={ 'vertical-flex' }>
                    <div className={ 'vertical-flex-fix head-holder' }>
                        <TopSearchHeader backLink={ '/home' } backLabel={ 'Back' } addIcon={ 'back-icon' } currentPage={ 'mashup' } { ...this.props } />
                    </div>
                    <div className={ 'vertical-flex-flexible ts ts-quick ts-ease-out main-content' }>
                        <MashupSlideHolder 
                            title={ 'GCAD Mashup' }
                            mashupSlide={ slideCols } 
                            onSlideChange={ this.onSlideChange }
                            onSlideClick={ this.onSlideClick }
                            onDotsClick={ this.onDotsClick }
                            slide1Feat={ slide1Feat } 
                            slide2Feat={ slide2Feat } 
                            slide3Feat={ slide3Feat } 
                            slide4Feat={ slide4Feat } 
                            slide1Pos={ slide1Pos } 
                            slide2Pos={ slide2Pos } 
                            slide3Pos={ slide3Pos } 
                            slide4Pos={ slide4Pos } 
                        />
                    </div>
                    <div className={ 'vertical-flex-flexible ts ts-quick ts-ease-out search-content' }>
                        <SearchResultsHolder { ...this.props } />
                    </div>
                </div>
            </div>
        )
    }
}

export default MashupPage;