import React, { Component } from 'react';
import { TopSearchHeader, LoopSlideHolder, SingleSlideHolder, SearchResultsHolder } from '../../organisms';
import { getHomeProjectsLoop, getHomeEventsLoop, getMashUpThumnail } from './../../../API'
import './style.css';

class HomePage extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            load        : false,
            projectLoop : false,
            eventLoop   : false,
            mashUpThumb : false,
            idleTime    : 0            
        };
        this.getData                = this.getData.bind( this );
        this.setIdleTimerCounter    = this.setIdleTimerCounter.bind( this );
        this.autoComeBack           = this.autoComeBack.bind( this );
        this.resetIdleTimer         = this.resetIdleTimer.bind( this );

    }
    
    getData() {       

        getMashUpThumnail().then( ( response ) => {
            this.setState({ 
                mashUpThumb : response.data,
            })
        }).catch( ( error ) => { 
            this.props.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });

        getHomeEventsLoop().then( ( response ) => {
            this.setState({ 
                eventLoop   : response.data,
            });
        }).catch( ( error ) => { 
            this.props.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });
                
        getHomeProjectsLoop().then( ( response ) => {
            this.setState({ 
                projectLoop : response.data,
                slideMax    : response.data.length,
                load        : true,
            });
        }).catch( ( error ) => { 
            this.props.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });
    }
    
    resetIdleTimer() {
        this.setState( { idleTime : 0 });
    }
    
    setIdleTimerCounter() {
        setInterval( () => {
            this.setState( { idleTime : this.state.idleTime + 5 }, () => { this.autoComeBack( this.props.maxIdleTime ) } )
        } , 5000 );
    }

    autoComeBack( max ) {
        if ( this.state.idleTime >= max ) {
            this.props.history.push('/');
        }
    }

    componentDidMount() {        
        this.getData();
        this.setIdleTimerCounter();
    }
    
    componentWillUnmount() {
        clearInterval( this.state.idleTime );
    }

    render() {

        var { projectLoop, eventLoop, mashUpThumb } = this.state;
        var loadClass = ( this.state.load ) ? 'loaded' : 'fetching'; 
                    
        return(
            <div className={ 'page home-page ' + loadClass }>
                <div className={ 'vertical-flex' }>
                    <div className={ 'vertical-flex-fix head-holder' }>
                    
                        <TopSearchHeader backLink={ '/' } backLabel={ 'Log Out' } currentPage={ 'home' } resetIdleTimer={ this.resetIdleTimer } { ...this.props } />
                    
                    </div>
                    
                    <div className={ 'vertical-flex-flexible main-content ts ts-quick ts-ease-out' }>
                        
                        <div className={ 'showcase-loop-holder ts ts-slow ts-ease-out' }>
                            <LoopSlideHolder title={ 'Showcase' } addClass={ 'project-showcase' } slidesPerPage={ 3 } slideLoop={ projectLoop } type={ 'projects' } />
                        </div>
                        
                        <div className={ 'events-loop-holder ts ts-ease-out ts-slow' }>
                            <div className={'horizontal-flex inner-wrapper'}>
                                <div className={ 'single-slide-home horizontal-flex-fix' }>
                                    <SingleSlideHolder title={ 'Mashup' } addClass={ 'slider-showcase' } slidesPerPage={ 4 } slideLoop={ mashUpThumb } type={ 'slider' } />
                                </div>
                                <div className={ 'event-slide-home horizontal-flex-flexible' }>
                                    <LoopSlideHolder title={ 'Events' } addClass={ 'events-showcase' } slidesPerPage={ 4 } slideLoop={ eventLoop } type={ 'events' } />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={ 'vertical-flex-flexible ts ts-quick ts-ease-out search-content' }>
                        <SearchResultsHolder { ...this.props } />
                    </div>
                </div>
            </div>
        )
    }
}

export default HomePage;