import React, { Component } from 'react';
import { TopSearchHeader, ProjectInfoHolder, ScreenPreviewHolder, LoopSlideHolder, SearchResultsHolder } from '../../organisms';
import { getSingleProjectInfo } from './../../../API'
import { setDisplayPreviewSendMessage, setTriggerStatusSendMessage } from '../../../WS/'
import './style.css';

class ProjectPage extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            load            : false,
            triggerStatus   : true,
            displayPreview  : 0,
            projectInfo     : false,
            isSliding       : true,
            idleTime        : 0,
            isSliding       : true
        };
        this.setTriggerStatus       = this.setTriggerStatus.bind( this );
        this.setDisplayPreview      = this.setDisplayPreview.bind( this );
        this.setIdleTimerCounter    = this.setIdleTimerCounter.bind( this );
        this.autoComeBack           = this.autoComeBack.bind( this );
        this.getData                = this.getData.bind( this );
        this.resetIdleTimer         = this.resetIdleTimer.bind( this );
        this.onLoadSlide            = this.onLoadSlide.bind( this );
    }

    getData() {
        getSingleProjectInfo( this.props.match.params.wpid ).then( ( response ) => {
            this.setState({ 
                load        : true,
                projectInfo : response.data 
            });
        }).catch( ( error ) => { 
            this.props.setAPIError( error );
            this.setState({ 
                load : true,
            });
        });
    }

    resetIdleTimer() {
        this.setState( { idleTime : 0 });
    }

    setTriggerStatus() {
        this.setState( ( prevState ) => { return { triggerStatus : !prevState.triggerStatus, idleTime : 0 } } );
        setTriggerStatusSendMessage( this ); 
    }

    getVideoWallSocketHandler( slide ) {
        if ( this.props.WSReady ) {
            setDisplayPreviewSendMessage( this, slide );        
        }
    }

    onLoadSlide() {
        /*
        this.setState({ 
            isSliding : false,
        });
        */
    }

    setDisplayPreview( slide ) {
        this.props.setIsScreenNotReady()
        setTimeout( () => {
            this.setState({ 
                isSliding       : true,
                idleTime        : 0,
                displayPreview  : slide,
            }, () => { this.getVideoWallSocketHandler( this.state.displayPreview ) } )
        }, 200 ); 
    }
    
    autoComeBack( max ) {
        if ( this.state.idleTime >= max ) {
            this.props.history.push('/');
        }
    }
    
    setIdleTimerCounter() {
        setInterval( () => {
            this.setState( { idleTime : this.state.idleTime + 5 }, () => { this.autoComeBack( this.props.maxIdleTime ) } )
        } , 5000 );
    }
    
    componentDidMount() {
        this.getData();
        this.setDisplayPreview( 0 );
        this.setIdleTimerCounter();
    }

    componentWillUnmount() {
        clearInterval( this.state.idleTime ); 
    }

    componentDidUpdate( prevProps, prevState ) {
        if ( prevProps.WSReady === false && this.props.WSReady ) {
            this.getVideoWallSocketHandler( this.props.displayPreview );            
        }
    }

    hasCurrentLayoutAddInfo( previewLoop ) {
        var cLyt = ( previewLoop ) ? previewLoop[ this.state.displayPreview ].layout : false;
        return ( cLyt === 'event-layout' ||  cLyt === 'project-layout' /* || cLyt === 'demo-layout' || cLyt === 'tricol-layout' || cLyt === 'quote-layout' */ );
    }

    render() {
        
        var { projectInfo, load, triggerStatus, displayPreview } = this.state;
        var loadClass = ( load ) ? 'loaded' : 'fetching'; 
        var wpid = this.props.match.params.wpid;
        var hasAddInfo = this.hasCurrentLayoutAddInfo( projectInfo.screens );
        var slideClass = ( !this.props.isScreenReady ) ? 'is-sliding' : 'no-sliding';

        return(
            <div className={ 'page project-page ' + loadClass } >
                <div className={ 'vertical-flex' }>
                    <div className={ 'vertical-flex-fix head-holder' }>
                        <TopSearchHeader backLink={ '/home' } backLabel={ 'Back' } addIcon={ 'back-icon' } currentPage={ 'poject-' + wpid } { ...this.props } />
                    </div>
                    <div className={ 'vertical-flex-flexible ts ts-quick ts-ease-out main-content' }>
                        <div className={ 'ts ts-slow ts-ease-out video-holder' }>
                            <ScreenPreviewHolder 
                                triggerStatus={ triggerStatus } 
                                display={ displayPreview }  
                                hasAddInfo={ hasAddInfo } 
                                isSliding={ !this.props.isScreenReady }
                                slideLoop={ projectInfo.screens }
                                webSocket= { this.props.webSocket }
                                resetIdleTimer={ this.resetIdleTimer } 
                                onLoadSlide={ this.onLoadSlide }
                                videoIsReady={ this.props.videoIsReady }
                                videoIsEnd={ this.props.videoIsEnd }
                                videoReset={ this.props.videoReset }
                                WSReady={ this.props.WSReady }
                                videoIsBuffering={ this.props.videoIsBuffering }
                            />
                        </div>
                        <div className={ 'full-inner-wrapper ts ts-slow ts-ease-out info-holder' }>
                            <ProjectInfoHolder 
                                triggerStatus={ triggerStatus }
                                onButtonClick={ this.setTriggerStatus } 
                                hasAddInfo={ hasAddInfo }
                                { ...projectInfo }
                            />
                        </div>
                        <div className={ 'inner-wrapper ts ts-slow ts-ease-out slider-holder ' + slideClass }>
                            <LoopSlideHolder slidesPerPage={ 4 } slideLoop={ projectInfo.screens } type={ 'screens' } thumbOnClick={ this.setDisplayPreview } display={ displayPreview } />
                        </div>
                    </div>

                    <div className={ 'vertical-flex-flexible ts ts-quick ts-ease-out search-content' }>
                        <SearchResultsHolder { ...this.props } />
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectPage;